<template>
    <footer class="footer container-fluid col-md-12 col-sm-12">
    <a class="col-md-3 col-sm-4 offset-md-1 terminos">Politica de privacidad-Términos y condiciones</a>
    <a class="col-md-1 col-sm-1 offset-md-5 offset-sm-5" href="https://www.facebook.com/iluminandocaminospsicintegral"
      target="_blank"><img class="redes" src="@\assets\facebook.svg" alt="facebook" /></a>
    <a class="col-md-1 col-sm-1" href="https://www.youtube.com/channel/UCf4fJKz9FQqNxRj0chpTVaw/videos"
      target="_blank"><img class="redes" src="@\assets\youtube.svg" alt="youtube" /></a>
      <a class="col-md-1 col-sm-1" href="https://www.facebook.com/sharer/sharer.php?u=https://iluminandocaminos.blog"
  target="_blank">
  <img class="redes" src="@\assets\compartir.svg" alt="Compartir en Facebook" />
</a>
  </footer>
</template>

<script>
export default {
};
</script>
<template>
  <div>
    <div class="row">
      <div class="col-md-2 navbar-wrapper">
        <div class="navbar-backoffice">
          <a class="navbar-item">
            <i class="fa-solid fa-house" style="color: #581a74"></i>
          </a>
          <a @click="cerrarSesion" href="#" class="navbar-item">
            <i
              class="fa-solid fa-right-from-bracket"
              style="color: #581a74"
            ></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="table-container">
    <div class="table-wrapper">
      <div class="col-lg-12 button-create">
        <a href="/crear"
          ><button type="button" class="btn btn-success">
            Crear <i class="fa-solid fa-plus"></i></button
        ></a>
      </div>
      <div class="table-fixed-height">
        <table class="table tabla-con-botones">
          <thead>
            <tr class="estilos-th">
              <th scope="col" style="width: 50px">ID</th>
              <th scope="col" style="width: 400px">Titulo</th>
              <th scope="col" style="width: 20px">Estado</th>
              <th scope="col" style="width: 200px">Fecha</th>
              <th scope="col" style="width: 200px">Hora</th>
              <th scope="col" style="width: 150px">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="estilos-th"
              v-for="publicacion in paginatedPublicaciones.reverse()"
              :key="publicacion.id"
            >
              <th scope="row">{{ publicacion.Id }}</th>
              <td>{{ truncateText(publicacion.Titulo, 40) }}</td>
              <td>
                <span
                  :class="
                    publicacion.Estado == 1
                      ? 'alert alert-success'
                      : 'alert alert-danger'
                  "
                  role="alert"
                >
                  {{ publicacion.Estado == 1 ? "Activo" : "Inactivo" }}
                </span>
              </td>
              <td>{{ publicacion.Fecha }}</td>
              <td>{{ publicacion.Hora }}</td>
              <td>
                <div class="button-container">
                  <router-link
                    :to="{ name: 'editar', params: { Id: publicacion.Id } }"
                    class="btn btn-primary btn-sm"
                    ><i class="fa-solid fa-pen"></i
                  ></router-link>

                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    data-bs-toggle="modal"
                    :data-bs-target="'#exampleModal' + publicacion.Id"
                  >
                    <i class="fa-solid fa-trash" style="color: #ffffff"></i>
                  </button>
                  <!-- <button class="btn btn-warning btn-sm">
                    <i class="fa-solid fa-paper-plane"></i>
                  </button> -->
                </div>
                <div class="modal fade" :id="'exampleModal' + publicacion.Id" tabindex="-1" :aria-labelledby="'exampleModalLabel' + publicacion.Id" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">¿Estas segura?</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        Se eliminar la publicacion: <b>{{ publicacion.Titulo }} </b>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger" v-on:click="borrarPublicacion(publicacion.Id)">Si,borrar publicación</button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--TODO:Agregar toast de borrado,crear y actualizar
    <div class="toast toast-container bottom-0 end-0 text-bg-danger" role="alert" aria-live="assertive" aria-atomic="true" id="liveToast">
  <div class="d-flex ">
    <div class="toast-body">
      Publicación borrada
    </div>
    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
</div> -->
      <div class="col-md-12 container-paginacion">
        <ul class="pagination">
          <li
            class="page-item"
            v-for="page in pageCount"
            :key="page"
            :class="{ active: currentPage === page }"
          >
            <a class="page-link" @click="setCurrentPage(page)">{{ page }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.navbar-wrapper {
  background-color: #9f70d2;
  margin-left: 20px;
  border-radius: 20px;
  width: 80px;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Mantén la posición fija pero ajusta el posicionamiento */
  position: fixed;
  top: 20px; /* Ajusta la distancia desde la parte superior según sea necesario */
  z-index: 1;
}

.navbar-backoffice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95vh;
  padding: 20px;
}

.navbar-item {
  color: white;
  font-size: 24px;
  margin-bottom: 10px;
}

.navbar-item:last-child {
  margin-bottom: 0;
}

.navbar-item i {
  margin-right: 5px;
}

.centrar-vertical {
  display: flex;
  align-items: center; /* Centrar verticalmente */
  justify-content: center; /* Centrar horizontalmente */
  height: 100vh; /* Opcional: ajusta la altura a tu preferencia */
}

.button-container > .btn {
  margin-right: 5px; /* Ajusta el espacio horizontal entre los botones */
}

.table-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.table-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Ajusta el espacio entre el botón y la tabla */
}

.button-create {
  display: flex;
  justify-content: end;
  padding-bottom: 5px;
}

/* Estilos para el contenedor de la tabla */
.tabla-con-botones {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.table-fixed-height {
  height: 550px; /* Ajusta la altura según tus necesidades */
  overflow-y: auto;
}

/* Estilos para centrar los th dentro de la thead */
.estilos-th {
  text-align: center;
}

/* Estilos adicionales para la thead */
.tabla-con-botones thead {
  background-color: #9f70d2;
  color: white;
}

.container-paginacion {
  display: flex;
  justify-content: end;
}

.alert-success {
  border-radius: 10px;
  padding: 3px 5px;
  /* Otros estilos para cuando está activo */
}

.alert-danger {
  border-radius: 10px;
  padding: 3px 5px;
  /* Otros estilos para cuando está inactivo */
}

</style>

<script>
import { API_ENDPOINTS } from '../router/api.js';

export default {
  data() {
    return {
      publicaciones: [],
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  created() {
    this.consultarPublicaciones();
  },
  computed: {
    pageCount() {
      return Math.ceil(this.publicaciones.length / this.itemsPerPage);
    },
    paginatedPublicaciones() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.publicaciones.slice(startIndex, endIndex);
    },
  },
  methods: {
    actualizarEstadoPublicacion(publicacion) {
    const fechaActual = new Date();
    const fechaPublicacion = new Date(
      publicacion.Fecha + " " + publicacion.Hora
    );

    if (fechaActual >= fechaPublicacion && publicacion.Estado !== 1) {
      // Realizar la solicitud POST para actualizar el estado de la publicación
      const datosEnviar = {
        id: publicacion.Id,
        estado: 1,
      };

      fetch(API_ENDPOINTS.estado + publicacion.Id, {
        method: "POST",
        body: JSON.stringify(datosEnviar),
      })
        .then((respuesta) => respuesta.json())
        .then((datosRespuesta) => {
          console.log(datosRespuesta);
        })
        .catch(console.log);
    }
  },
  consultarPublicaciones() {
  fetch(API_ENDPOINTS.publicaciones)
    .then((respuesta) => respuesta.json())
    .then((datosRespuesta) => {
      if (typeof datosRespuesta[0].success === "undefined") {
        this.publicaciones = datosRespuesta;

        // Verificar y actualizar el estado de las publicaciones
        this.publicaciones.forEach((publicacion) => {
          this.actualizarEstadoPublicacion(publicacion);
        });
      }
    })
    .catch(console.log);
},
    setCurrentPage(page) {
      this.currentPage = page;
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      } else {
        return text;
      }
    },
    borrarPublicacion(id) {
      fetch(API_ENDPOINTS.borrar + id)
        .then((respuesta) => respuesta.json())
        .then((datosRespuesta) => {
          console.log(datosRespuesta);
          location.reload();
        })
        .catch(console.log);
    },
    cerrarSesion() {
      // Actualizar el estado de inicio de sesión a "false"
      localStorage.setItem("isLoggedIn", "false");

      // Redirigir a la página de inicio de sesión
      this.$router.push("/admin");
    },
  },
  //Codigo para generar un toast
  // mounted(){
  //   const toastTrigger = document.getElementById('liveToastBtn');
  //   const toastLiveExample = document.getElementById('liveToast');

  //   if (toastTrigger) {
  //     toastTrigger.addEventListener('click', () => {
  //       const toast = new bootstrap.Toast(toastLiveExample);
  //       toast.show();
  //       console.log("borrado")
  //     });
  //   }
  // }
};
</script>

<template>
  <div class="card-blog">
    <div class="container mt-5">
      <div class="row">
        <div
          v-for="(publicacion) in publicacionesFiltradasOrdenadas"
          :key="publicacion.id"
          class="mb-4 col-lg-3 col-md-4 col-sm-6 col-12"
        >
          <router-link
            :to="{ name: 'publicacion', params: { Id: publicacion.Id } }"
            class="btn d-block"
          >
            <div class="card">
              <div class="card-img-container">
                <img
                  :src="publicacion.Imagen"
                  class="card-img-top"
                  alt="Imagen"
                />
              </div>
              <div class="card-body">
                <h5 class="card-title">{{ truncateText(publicacion.Titulo, 21) }}</h5>
              </div>
              <div class="card-footer text-left">
                <small class="text-muted">Publicado: {{ publicacion.Fecha }}</small>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-blog {
  min-height: 100vh;
}

.card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  overflow: hidden;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 2px 4px rgba(93, 15, 129, 0.329);
}

.card:hover .card-img-top {
  transform: scale(1.01);
}

.card-img-container {
  width: 100%;
  height: auto; /* Cambiamos a altura automática */
  max-height: 200px; /* Agregamos una altura máxima */
  overflow: hidden;
}

.card-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; /* Para centrar la imagen */
}
</style>


<script>
import { API_ENDPOINTS } from '../router/api.js';

export default {
  data() {
    return {
      publicaciones: [],
    };
  },
  created() {
    this.consultarPublicaciones();
  },
  computed: {
    publicacionesFiltradasOrdenadas() {
      const publicacionesFiltradas = this.publicacionesFiltradas;
      return publicacionesFiltradas.sort((a, b) => {
        const fechaPublicacionA = new Date(a.Fecha + " " + a.Hora);
        const fechaPublicacionB = new Date(b.Fecha + " " + b.Hora);
        return fechaPublicacionB - fechaPublicacionA;
      });
    },
    publicacionesFiltradas() {
      var fechaActual = new Date();
      return this.publicaciones.filter((publicacion) => {
        var fechaPublicacion = new Date(publicacion.Fecha + " " + publicacion.Hora);

        if (fechaActual >= fechaPublicacion && publicacion.Estado !== 1) {
          // Realizar la solicitud de actualización del estado
          var datosEnviar = {
            id: publicacion.Id,
            estado: 1,
          };

          fetch(API_ENDPOINTS.estado + publicacion.Id, {
            method: "POST",
            body: JSON.stringify(datosEnviar),
          })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              console.log(datosRespuesta);
              // Actualizar el estado de la publicación localmente
              publicacion.Estado = 1;
            });
        }

        return fechaActual >= fechaPublicacion;
      });
    },
  },
  methods: {
    consultarPublicaciones() {
      fetch(API_ENDPOINTS.publicaciones)
        .then((respuesta) => respuesta.json())
        .then((datosRespuesta) => {
          if (typeof datosRespuesta[0].success === "undefined") {
            this.publicaciones = datosRespuesta;
          }
        })
        .catch(console.log);
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      } else {
        return text;
      }
    },
  },
};
</script>

<template>
  <nav class="navbar sticky-top navbar-dark navbar-expand-lg col-md-12">
    <div class="container-fluid">
        <a class="navbar-brand logo" href="/">
          <img :src="imageSource" alt="logo_ilumcam" width="40" height="45" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
          <div class="nocollapse navbar-nav col-md-12">
            <a class="nav-link efecto col-md-1" href="/#Nosotros">Nosotros</a>
            <a class="nav-link efecto col-md-1" href="/#Contactanos">Contacto</a>
            <a class="nav-link efecto col-md-1" href="/blog">Blog</a>
          </div>
        </div>
      </div>
    </nav>
</template>

<script>

export default {
  data() {
    return {
      iconSrc: 'https://cdn.lordicon.com/uzrqzyei.json',
      imageSource: require("@/assets/logo.png"),
    };
  },
};
</script>

<style>
</style>

// api/index.js

// const API_BASE_URL = "http://localhost/publicaciones/";
// const API_BASE_URL_2 = "http://localhost/admin/";

//!url a las apis
const API_BASE_URL = "https://iluminandocaminos.blog/api/publicaciones/index.php/";
const API_BASE_URL_2 = "https://iluminandocaminos.blog/api/admin/index.php/";

export const API_ENDPOINTS = {
  // API Publicaciones
    publicaciones: `${API_BASE_URL}`,
    estado: `${API_BASE_URL}?estado=`,
    borrar: `${API_BASE_URL}?borrar=`,
    consultar: `${API_BASE_URL}?consultar=`,
    insertar: `${API_BASE_URL}?insertar=1`,
    actualizar: `${API_BASE_URL}?actualizar=`,

  // API 2
    login: `${API_BASE_URL_2}`,
};

<!-- <template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 navbar-wrapper">
        <div class="navbar-backoffice">
          <a href="../listar" class="navbar-item">
            <i class="fa-solid fa-house" style="color: #581a74;"></i>
          </a>
          <a @click="cerrarSesion" href="#" class="navbar-item">
            <i class="fa-solid fa-right-from-bracket" style="color: #581a74;"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <h1>Crear Publicación</h1>
    <form v-on:submit.prevent="guardarPublicacion">
      <div class="form-group">
        <label for="titulo">Título</label>
        <input type="text" class="form-control" id="titulo" v-model="publicacion.Titulo" required>
      </div>
      <div class="form-group">
        <label for="formFile" class="form-label">Imagen de portada</label>
        <input class="form-control" type="file" id="formFile" @change="onFileChange" required>
      </div>
      <div class="row">
        <div class="col">
          <label for="hora">Hora</label>
          <input type="time" class="form-control" id="hora" v-model="publicacion.Hora" required>
        </div>
        <div class="col">
          <label for="fecha">Fecha</label>
          <input type="date" class="form-control" id="fecha" v-model="publicacion.Fecha" required>
        </div>
      </div>
      <div class="form-group">
        <label for="descripcion">Descripción</label>
        <QuillEditor
      theme="snow"
      class="form-control"
      id="descripcion"
      :value="publicacion.Descripcion"
      @input="updateDescripcion"
      required
      toolbar="full"
      :modules="modules"
    ></QuillEditor>
      </div>
      <div class="button-container">
        <button type="submit" class="btn btn-primary">Guardar</button>
        <button type="button" class="btn btn-secondary" @click="cancelar">Cancelar</button>
      </div>
    </form>
  </div>
</template>

<script>
import Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter'

Quill.register("modules/blotFormatter", BlotFormatter);

export default {
  data() {
    return {
      theme: 'snow',
      editorOptions: {
        modules: {
          BlotFormatter,
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link','image'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
          ],
        },
      },
      publicacion: {
        Titulo: '',
        Imagen: '',
        Descripcion: '',
        Hora: '',
        Fecha: ''
      },
      modules: null,
    };
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Data = reader.result;
        this.publicacion.Imagen = base64Data;
      };

      reader.readAsDataURL(file);
    },
    guardarPublicacion() {
      // Lógica para guardar la publicación
      var datosEnviar = {
        titulo: this.publicacion.Titulo,
        imagen: this.publicacion.Imagen,
        descripcion: this.publicacion.Descripcion = this.$refs.quillEditor.getHTML(),
        estado: 0,
        fecha: this.publicacion.Fecha,
        hora: this.publicacion.Hora
      };
      fetch("http://localhost/publicaciones/?insertar=1", {
        method: "POST",
        body: JSON.stringify(datosEnviar)
      })
        .then(respuesta => respuesta.json())
        .then((datosRespuesta) => {
          console.log(datosRespuesta);
          window.location.href = 'listar';
        });
    },
    cancelar() {
      // Lógica para cancelar la creación de la publicación
      this.publicacion = {
        Titulo: '',
        Imagen: '',
        Descripcion: '',
        Hora: '',
        Fecha: ''
      };
      window.location.href = 'listar';
    },
    cerrarSesion() {
      // Actualizar el estado de inicio de sesión a "false"
      localStorage.setItem('isLoggedIn', 'false');

      // Redirigir a la página de inicio de sesión
      this.$router.push('/admin');
    }
  }
};
</script>

<style scoped>
.navbar-wrapper {
  background-color: #9f70d2;
  margin-left: 20px;
  border-radius: 20px;
  width: 80px;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Agregar las siguientes propiedades para separar y centrar verticalmente */
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.navbar-backoffice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95vh;
  padding: 20px;
}

.navbar-item {
  color: white;
  font-size: 24px;
  margin-bottom: 10px;
}

.navbar-item:last-child {
  margin-bottom: 0;
}

.navbar-item i {
  margin-right: 5px;
}

.container {
  max-width: 70vw;
  margin: auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: space-between
}
</style> -->


<!--!REVISAR CODIGO  -->
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 navbar-wrapper">
        <div class="navbar-backoffice">
          <a href="../listar" class="navbar-item">
            <i class="fa-solid fa-house" style="color: #581a74;"></i>
          </a>
          <a @click="cerrarSesion" href="#" class="navbar-item">
            <i class="fa-solid fa-right-from-bracket" style="color: #581a74;"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <h1>Crear Publicación</h1>
    <form v-on:submit.prevent="guardarPublicacion">
      <div class="form-group">
        <label for="titulo">Título</label>
        <input type="text" class="form-control" id="titulo" v-model="publicacion.Titulo" required>
      </div>
      <div class="form-group">
        <label for="formFile" class="form-label">Imagen de portada</label>
        <input class="form-control" type="file" id="formFile" @change="onFileChange" required>
      </div>
      <div class="row">
        <div class="col">
          <label for="hora">Hora</label>
          <input type="time" class="form-control" id="hora" v-model="publicacion.Hora" required>
        </div>
        <div class="col">
          <label for="fecha">Fecha</label>
          <input type="date" class="form-control" id="fecha" v-model="publicacion.Fecha" required>
        </div>
      </div>
      <div class="form-group">
        <label for="descripcion">Descripción</label>
        <div class="quill-editor-container">
          <div ref="quillEditor" class="quill-editor"></div>
        </div>
      </div>
      <div class="button-container">
        <button type="submit" class="btn btn-primary">Guardar</button>
        <button type="button" class="btn btn-secondary" @click="cancelar">Cancelar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { API_ENDPOINTS } from '../router/api.js';
import Quill from 'quill';
import BlotFormatter, { AlignAction, DeleteAction, ImageSpec, ResizeAction, IframeVideoSpec} from 'quill-blot-formatter';

Quill.register('modules/blotFormatter', BlotFormatter);


class CustomImageSpec extends ImageSpec {
  getActions() {
    return [AlignAction, DeleteAction, ResizeAction];
  }
}

class CustomVideoSpec extends IframeVideoSpec {
  getActions() {
    return [AlignAction, DeleteAction, ResizeAction];
  }
}

export default {
  data() {
    return {
      publicacion: {
        Titulo: '',
        Imagen: '',
        Descripcion: '',
        Hora: '',
        Fecha: ''
      },
      quillInstance: null,
    };
  },
  mounted() {
    this.initializeQuillEditor();
  },
  methods: {
    initializeQuillEditor() {
      const toolbarOptions = [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': 1 }, { 'header': 2 }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['link','image','video'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean']
      ];

      this.quillInstance = new Quill(this.$refs.quillEditor, {
        modules: {
          toolbar: toolbarOptions,
          blotFormatter: {
            specs: [CustomImageSpec,CustomVideoSpec],
            overlay: {
              style: {
                border: '2px solid purple',
              },
            },
          },
        },
        theme: 'snow',
      });
    },
    onFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Data = reader.result;
        this.publicacion.Imagen = base64Data;
      };

      reader.readAsDataURL(file);
    },
    guardarPublicacion() {
      var datosEnviar = {
        titulo: this.publicacion.Titulo,
        imagen: this.publicacion.Imagen,
        descripcion: this.publicacion.Descripcion = this.quillInstance.root.innerHTML,
        estado: 0,
        fecha: this.publicacion.Fecha,
        hora: this.publicacion.Hora
      };
      fetch(API_ENDPOINTS.insertar, {
        method: "POST",
        body: JSON.stringify(datosEnviar)
      })
        .then(respuesta => respuesta.json())
        .then((datosRespuesta) => {
          console.log(datosRespuesta);
          window.location.href = 'listar';
        });
    },
    cancelar() {
      this.publicacion = {
        Titulo: '',
        Imagen: '',
        Descripcion: '',
        Hora: '',
        Fecha: ''
      };
      window.location.href = 'listar';
    },
    cerrarSesion() {
      localStorage.setItem('isLoggedIn', 'false');
      this.$router.push('/admin');
    }
  }
};
</script>

<style scoped>
.navbar-wrapper {
  background-color: #9f70d2;
  margin-left: 20px;
  border-radius: 20px;
  width: 80px;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.navbar-backoffice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95vh;
  padding: 20px;
}

.navbar-item {
  color: white;
  font-size: 24px;
  margin-bottom: 10px;
}

.navbar-item:last-child {
  margin-bottom: 0;
}

.navbar-item i {
  margin-right: 5px;
}

.container {
  max-width: 70vw;
  margin: auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: space-between
}

</style>
